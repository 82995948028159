#contactDetails ul li a, a[href^=tel] {
  color: #444;
  text-decoration: none;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -ms-transition: all .3s ease-in;
  transition: all .3s ease-in; }

#contactDetails ul li a:hover {
  color: #1e90ff; }

.sectionTitle {
  float: left;
  width: 20%; }

.sectionContent {
  float: right;
  width: 77.5%; }

.sectionTitle h1 {
  font-family: 'Rokkitt', Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 1.5em;
  color: silver; }

.sectionContent h2 {
  font-family: 'Rokkitt', Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  margin-bottom: -2px; }

.sectionContent h3 {
  font-family: 'Rokkitt', Helvetica, Arial, sans-serif;
  font-size: 1.4em;
  margin-bottom: -2px;
  margin-top: 7px; }

.subDetails {
  font-size: 0.8em;
  font-style: italic;
  margin-bottom: 3px; }

.keySkills {
  list-style-type: none;
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  margin-bottom: 20px;
  font-size: 1em;
  color: #444; }

.keySkills ul li {
  margin-bottom: 3px; }

@media all and (min-width: 602px) and (max-width: 800px) {
  .keySkills {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2; } }

@media all and (max-width: 726px) {
  .sectionTitle, .sectionContent {
    float: none;
    width: 100%; }
  .sectionTitle {
    margin-left: -2px;
    font-size: 1.25em; }
  .keySkills {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2; }
  .sectionContent p {
    font-size: .9em; } }

p {
  text-align: justify; }
